exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-blog-add-code-highlighting-to-mdx-mdx": () => import("./../../../src/pages/blog/add-code-highlighting-to-mdx.mdx" /* webpackChunkName: "component---src-pages-blog-add-code-highlighting-to-mdx-mdx" */),
  "component---src-pages-blog-add-column-to-postgres-md": () => import("./../../../src/pages/blog/add-column-to-postgres.md" /* webpackChunkName: "component---src-pages-blog-add-column-to-postgres-md" */),
  "component---src-pages-blog-add-search-mdx-mdx": () => import("./../../../src/pages/blog/add-search-mdx.mdx" /* webpackChunkName: "component---src-pages-blog-add-search-mdx-mdx" */),
  "component---src-pages-blog-authenticate-requests-webhook-md": () => import("./../../../src/pages/blog/authenticate-requests-webhook.md" /* webpackChunkName: "component---src-pages-blog-authenticate-requests-webhook-md" */),
  "component---src-pages-blog-c-data-types-mdx": () => import("./../../../src/pages/blog/c-data-types.mdx" /* webpackChunkName: "component---src-pages-blog-c-data-types-mdx" */),
  "component---src-pages-blog-change-css-safari-only-md": () => import("./../../../src/pages/blog/change-css-safari-only.md" /* webpackChunkName: "component---src-pages-blog-change-css-safari-only-md" */),
  "component---src-pages-blog-command-line-tricks-md": () => import("./../../../src/pages/blog/command-line-tricks.md" /* webpackChunkName: "component---src-pages-blog-command-line-tricks-md" */),
  "component---src-pages-blog-comparing-images-with-numpy-mdx": () => import("./../../../src/pages/blog/comparing-images-with-numpy.mdx" /* webpackChunkName: "component---src-pages-blog-comparing-images-with-numpy-mdx" */),
  "component---src-pages-blog-creating-a-jekyll-theme-md": () => import("./../../../src/pages/blog/creating-a-jekyll-theme.md" /* webpackChunkName: "component---src-pages-blog-creating-a-jekyll-theme-md" */),
  "component---src-pages-blog-creating-a-weather-app-md": () => import("./../../../src/pages/blog/creating-a-weather-app.md" /* webpackChunkName: "component---src-pages-blog-creating-a-weather-app-md" */),
  "component---src-pages-blog-dask-dashboard-inside-notebook-mdx": () => import("./../../../src/pages/blog/dask-dashboard-inside-notebook.mdx" /* webpackChunkName: "component---src-pages-blog-dask-dashboard-inside-notebook-mdx" */),
  "component---src-pages-blog-django-ninja-many-to-many-mdx": () => import("./../../../src/pages/blog/django-ninja-many-to-many.mdx" /* webpackChunkName: "component---src-pages-blog-django-ninja-many-to-many-mdx" */),
  "component---src-pages-blog-django-postgresql-tests-mdx": () => import("./../../../src/pages/blog/django-postgresql-tests.mdx" /* webpackChunkName: "component---src-pages-blog-django-postgresql-tests-mdx" */),
  "component---src-pages-blog-dynamodb-create-use-index-mdx": () => import("./../../../src/pages/blog/dynamodb-create-use-index.mdx" /* webpackChunkName: "component---src-pages-blog-dynamodb-create-use-index-mdx" */),
  "component---src-pages-blog-dynamodb-using-locally-mdx": () => import("./../../../src/pages/blog/dynamodb-using-locally.mdx" /* webpackChunkName: "component---src-pages-blog-dynamodb-using-locally-mdx" */),
  "component---src-pages-blog-filter-blog-posts-mdx": () => import("./../../../src/pages/blog/filter-blog-posts.mdx" /* webpackChunkName: "component---src-pages-blog-filter-blog-posts-mdx" */),
  "component---src-pages-blog-first-letter-spread-multiple-lines-mdx": () => import("./../../../src/pages/blog/first-letter-spread-multiple-lines.mdx" /* webpackChunkName: "component---src-pages-blog-first-letter-spread-multiple-lines-mdx" */),
  "component---src-pages-blog-gatsby-browser-soundcloud-mdx": () => import("./../../../src/pages/blog/gatsby-browser-soundcloud.mdx" /* webpackChunkName: "component---src-pages-blog-gatsby-browser-soundcloud-mdx" */),
  "component---src-pages-blog-getting-started-with-nx-mdx": () => import("./../../../src/pages/blog/getting-started-with-nx.mdx" /* webpackChunkName: "component---src-pages-blog-getting-started-with-nx-mdx" */),
  "component---src-pages-blog-git-aliases-mdx": () => import("./../../../src/pages/blog/git-aliases.mdx" /* webpackChunkName: "component---src-pages-blog-git-aliases-mdx" */),
  "component---src-pages-blog-git-commit-history-md": () => import("./../../../src/pages/blog/git-commit-history.md" /* webpackChunkName: "component---src-pages-blog-git-commit-history-md" */),
  "component---src-pages-blog-git-merge-conflicts-md": () => import("./../../../src/pages/blog/git-merge-conflicts.md" /* webpackChunkName: "component---src-pages-blog-git-merge-conflicts-md" */),
  "component---src-pages-blog-git-recover-deleted-files-md": () => import("./../../../src/pages/blog/git-recover-deleted-files.md" /* webpackChunkName: "component---src-pages-blog-git-recover-deleted-files-md" */),
  "component---src-pages-blog-global-gitignore-mdx": () => import("./../../../src/pages/blog/global-gitignore.mdx" /* webpackChunkName: "component---src-pages-blog-global-gitignore-mdx" */),
  "component---src-pages-blog-handle-form-submission-in-gatsby-with-airtable-md": () => import("./../../../src/pages/blog/handle-form-submission-in-gatsby-with-airtable.md" /* webpackChunkName: "component---src-pages-blog-handle-form-submission-in-gatsby-with-airtable-md" */),
  "component---src-pages-blog-highlight-text-with-sphinx-mdx": () => import("./../../../src/pages/blog/highlight-text-with-sphinx.mdx" /* webpackChunkName: "component---src-pages-blog-highlight-text-with-sphinx-mdx" */),
  "component---src-pages-blog-improving-a-classifier-training-speed-md": () => import("./../../../src/pages/blog/improving-a-classifier-training-speed.md" /* webpackChunkName: "component---src-pages-blog-improving-a-classifier-training-speed-md" */),
  "component---src-pages-blog-install-conda-in-docker-mdx": () => import("./../../../src/pages/blog/install-conda-in-docker.mdx" /* webpackChunkName: "component---src-pages-blog-install-conda-in-docker-mdx" */),
  "component---src-pages-blog-introduction-to-dask-mdx": () => import("./../../../src/pages/blog/introduction-to-dask.mdx" /* webpackChunkName: "component---src-pages-blog-introduction-to-dask-mdx" */),
  "component---src-pages-blog-introduction-to-vim-md": () => import("./../../../src/pages/blog/introduction-to-vim.md" /* webpackChunkName: "component---src-pages-blog-introduction-to-vim-md" */),
  "component---src-pages-blog-pyright-worker-threads-missing-mdx": () => import("./../../../src/pages/blog/pyright-worker-threads-missing.mdx" /* webpackChunkName: "component---src-pages-blog-pyright-worker-threads-missing-mdx" */),
  "component---src-pages-blog-python-dataclasses-mdx": () => import("./../../../src/pages/blog/python-dataclasses.mdx" /* webpackChunkName: "component---src-pages-blog-python-dataclasses-mdx" */),
  "component---src-pages-blog-running-pyscript-in-react-mdx": () => import("./../../../src/pages/blog/running-pyscript-in-react.mdx" /* webpackChunkName: "component---src-pages-blog-running-pyscript-in-react-mdx" */),
  "component---src-pages-blog-setup-gitlab-pages-mdx": () => import("./../../../src/pages/blog/setup-gitlab-pages.mdx" /* webpackChunkName: "component---src-pages-blog-setup-gitlab-pages-mdx" */),
  "component---src-pages-blog-test-for-aiohttp-client-errors-md": () => import("./../../../src/pages/blog/test-for-aiohttp-client-errors.md" /* webpackChunkName: "component---src-pages-blog-test-for-aiohttp-client-errors-md" */),
  "component---src-pages-blog-test-logging-called-md": () => import("./../../../src/pages/blog/test-logging-called.md" /* webpackChunkName: "component---src-pages-blog-test-logging-called-md" */),
  "component---src-pages-blog-the-enumerate-built-in-function-md": () => import("./../../../src/pages/blog/the-enumerate-built-in-function.md" /* webpackChunkName: "component---src-pages-blog-the-enumerate-built-in-function-md" */),
  "component---src-pages-blog-unittest-sys-exit-md": () => import("./../../../src/pages/blog/unittest-sys-exit.md" /* webpackChunkName: "component---src-pages-blog-unittest-sys-exit-md" */),
  "component---src-pages-blog-updating-npm-packages-mdx": () => import("./../../../src/pages/blog/updating-npm-packages.mdx" /* webpackChunkName: "component---src-pages-blog-updating-npm-packages-mdx" */),
  "component---src-pages-blog-upload-images-without-backend-mdx": () => import("./../../../src/pages/blog/upload-images-without-backend.mdx" /* webpackChunkName: "component---src-pages-blog-upload-images-without-backend-mdx" */),
  "component---src-pages-blog-version-control-git-mdx": () => import("./../../../src/pages/blog/version-control-git.mdx" /* webpackChunkName: "component---src-pages-blog-version-control-git-mdx" */),
  "component---src-pages-blog-weather-app-using-geolocation-and-vue-md": () => import("./../../../src/pages/blog/weather-app-using-geolocation-and-vue.md" /* webpackChunkName: "component---src-pages-blog-weather-app-using-geolocation-and-vue-md" */),
  "component---src-pages-books-apprenticeship-patterns-mdx": () => import("./../../../src/pages/books/apprenticeship-patterns.mdx" /* webpackChunkName: "component---src-pages-books-apprenticeship-patterns-mdx" */),
  "component---src-pages-books-clean-code-mdx": () => import("./../../../src/pages/books/clean-code.mdx" /* webpackChunkName: "component---src-pages-books-clean-code-mdx" */),
  "component---src-pages-books-code-complete-mdx": () => import("./../../../src/pages/books/code-complete.mdx" /* webpackChunkName: "component---src-pages-books-code-complete-mdx" */),
  "component---src-pages-books-code-mdx": () => import("./../../../src/pages/books/code.mdx" /* webpackChunkName: "component---src-pages-books-code-mdx" */),
  "component---src-pages-books-computer-networking-mdx": () => import("./../../../src/pages/books/computer-networking.mdx" /* webpackChunkName: "component---src-pages-books-computer-networking-mdx" */),
  "component---src-pages-books-cybersecurity-essentials-mdx": () => import("./../../../src/pages/books/cybersecurity-essentials.mdx" /* webpackChunkName: "component---src-pages-books-cybersecurity-essentials-mdx" */),
  "component---src-pages-books-design-patterns-mdx": () => import("./../../../src/pages/books/design-patterns.mdx" /* webpackChunkName: "component---src-pages-books-design-patterns-mdx" */),
  "component---src-pages-books-designing-distributed-systems-mdx": () => import("./../../../src/pages/books/designing-distributed-systems.mdx" /* webpackChunkName: "component---src-pages-books-designing-distributed-systems-mdx" */),
  "component---src-pages-books-from-architectures-to-operating-systems-mdx": () => import("./../../../src/pages/books/from-architectures-to-operating-systems.mdx" /* webpackChunkName: "component---src-pages-books-from-architectures-to-operating-systems-mdx" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-books-linux-kernel-programming-mdx": () => import("./../../../src/pages/books/linux-kernel-programming.mdx" /* webpackChunkName: "component---src-pages-books-linux-kernel-programming-mdx" */),
  "component---src-pages-books-operating-systems-three-easy-pieces-mdx": () => import("./../../../src/pages/books/operating-systems-three-easy-pieces.mdx" /* webpackChunkName: "component---src-pages-books-operating-systems-three-easy-pieces-mdx" */),
  "component---src-pages-books-pragmatic-thinking-and-learning-mdx": () => import("./../../../src/pages/books/pragmatic-thinking-and-learning.mdx" /* webpackChunkName: "component---src-pages-books-pragmatic-thinking-and-learning-mdx" */),
  "component---src-pages-books-refactoring-mdx": () => import("./../../../src/pages/books/refactoring.mdx" /* webpackChunkName: "component---src-pages-books-refactoring-mdx" */),
  "component---src-pages-books-structure-and-interpreation-of-computer-programs-mdx": () => import("./../../../src/pages/books/structure-and-interpreation-of-computer-programs.mdx" /* webpackChunkName: "component---src-pages-books-structure-and-interpreation-of-computer-programs-mdx" */),
  "component---src-pages-books-the-effective-engineer-mdx": () => import("./../../../src/pages/books/the-effective-engineer.mdx" /* webpackChunkName: "component---src-pages-books-the-effective-engineer-mdx" */),
  "component---src-pages-books-the-pragmatic-programmer-mdx": () => import("./../../../src/pages/books/the-pragmatic-programmer.mdx" /* webpackChunkName: "component---src-pages-books-the-pragmatic-programmer-mdx" */),
  "component---src-pages-cheatsheets-bash-mdx": () => import("./../../../src/pages/cheatsheets/bash.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-bash-mdx" */),
  "component---src-pages-cheatsheets-command-line-shortcuts-mdx": () => import("./../../../src/pages/cheatsheets/command-line-shortcuts.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-command-line-shortcuts-mdx" */),
  "component---src-pages-cheatsheets-docker-mdx": () => import("./../../../src/pages/cheatsheets/docker.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-docker-mdx" */),
  "component---src-pages-cheatsheets-js": () => import("./../../../src/pages/cheatsheets.js" /* webpackChunkName: "component---src-pages-cheatsheets-js" */),
  "component---src-pages-cheatsheets-lunarvim-mdx": () => import("./../../../src/pages/cheatsheets/lunarvim.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-lunarvim-mdx" */),
  "component---src-pages-cheatsheets-vim-mdx": () => import("./../../../src/pages/cheatsheets/vim.mdx" /* webpackChunkName: "component---src-pages-cheatsheets-vim-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-100-daysof-md": () => import("./../../../src/pages/projects/100daysof.md" /* webpackChunkName: "component---src-pages-projects-100-daysof-md" */),
  "component---src-pages-projects-findcommunitiestoday-md": () => import("./../../../src/pages/projects/findcommunitiestoday.md" /* webpackChunkName: "component---src-pages-projects-findcommunitiestoday-md" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-landingintech-mdx": () => import("./../../../src/pages/projects/landingintech.mdx" /* webpackChunkName: "component---src-pages-projects-landingintech-mdx" */),
  "component---src-pages-projects-landlbuild-md": () => import("./../../../src/pages/projects/landlbuild.md" /* webpackChunkName: "component---src-pages-projects-landlbuild-md" */),
  "component---src-pages-projects-mokens-md": () => import("./../../../src/pages/projects/mokens.md" /* webpackChunkName: "component---src-pages-projects-mokens-md" */),
  "component---src-pages-projects-opsdroid-md": () => import("./../../../src/pages/projects/opsdroid.md" /* webpackChunkName: "component---src-pages-projects-opsdroid-md" */),
  "component---src-pages-projects-opsdroid-skill-words-md": () => import("./../../../src/pages/projects/opsdroid-skill-words.md" /* webpackChunkName: "component---src-pages-projects-opsdroid-skill-words-md" */),
  "component---src-pages-projects-organizeacademy-md": () => import("./../../../src/pages/projects/organizeacademy.md" /* webpackChunkName: "component---src-pages-projects-organizeacademy-md" */),
  "component---src-pages-projects-quote-and-weather-md": () => import("./../../../src/pages/projects/quote-and-weather.md" /* webpackChunkName: "component---src-pages-projects-quote-and-weather-md" */),
  "component---src-pages-projects-thumbsupnews-md": () => import("./../../../src/pages/projects/thumbsupnews.md" /* webpackChunkName: "component---src-pages-projects-thumbsupnews-md" */),
  "component---src-pages-projects-tweetnalytics-md": () => import("./../../../src/pages/projects/tweetnalytics.md" /* webpackChunkName: "component---src-pages-projects-tweetnalytics-md" */),
  "component---src-pages-uses-js": () => import("./../../../src/pages/uses.js" /* webpackChunkName: "component---src-pages-uses-js" */)
}

